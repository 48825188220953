import { Stack } from '@mui/system';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
// utils
import { numberWithCommas } from '../../../utils/formatNumber';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function FreePostPopover() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const isSmaller450 = useResponsive('between', null, 0, 450);

  const handlePaymentRedirect = () => {
    // navigate(`${PATH_DASHBOARD.user.account}?activeTab=payment`);
  };

  return (
    <>
      {!isSmaller450 ? (
        <Stack direction={'row'} alignItems={'center'} columnGap={'12px'}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            columnGap={'8px'}
            sx={{
              fontSize: '13px',
              color: '#fff',
              background: `${theme.palette.primary.main}`,
              px: '10px',
              py: '4px',
              borderRadius: '8px',
              fontWeight: '700',
              cursor: 'pointer',
            }}
            onClick={handlePaymentRedirect}
          >
            <Iconify icon={'bx:dollar-circle'} width={20} height={20} />
            {numberWithCommas(user?.balance, '.')}
          </Stack>
        </Stack>
      ) : (
        ''
      )}
    </>
  );
}
