import { useState, useCallback, useRef, useEffect } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
// GoogleReCaptcha
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
// components
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// configs
import { ENVIRONMENT } from '../../../config';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { translate } = useLocales('login');
  const { loginB2B } = useAuth();
  const { themeMode } = useSettings();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [isSubmittingGoogle, setSubmittingGoogle] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await loginB2B(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {translate('heading')}
          </Typography>
        </Box>
      </Stack>

      <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{translate('message.invalid')}</Alert>}
            <RHFTextField name="email" label="Email" />

            <RHFTextField
              name="password"
              label={'Secret key'}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label={translate('remember')} />
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgetPassword}>
              {translate('forgot')}
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {translate('btn.login')}
          </LoadingButton>
        </FormProvider>
      </>
    </>
  );
}
