import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fAbbreviate(number, seperator = ',') {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  })
    .format(number)
    .replace('.', seperator);
}

export function numberWithCommas(x,seperator = ',') {
  if(!x){
    return '0';
  }
  return x.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
}