import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';
import OneSignal from 'react-onesignal';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import runOneSignal from '../utils/onesignal';
// redux
import { getConnectedsBySocial, disableSocialName, resetSocialState } from '../redux/slices/social';
import { getListQuest } from '../redux/slices/quest';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages,
      secretKey,
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages,
      secretKey,
    };
  },
  LOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages, secretKey } =
      action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages,
      secretKey,
    };
  },
  GOOGLELOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages,
    };
  },
  FACEBOOKLOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    typeStatuses: [],
    categories: [],
    categoriesDetail: [],
    cities: [],
    configs: {},
    siteAdPackages: [],
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATEPROFILE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  loginB2B: () => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
  facebookLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const secretKey = localStorage.getItem('secretKey');
        if (accessToken && isValidToken(accessToken) && secretKey) {
          setSession(accessToken, refreshToken);
          const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } =
            await getCommonCatalog();
          const user = await axios.get('api/v1/users/me/');
          // Get pages,groups have connected
          await getConnectedsBySocial('facebook', {});
          // Get list quest
          await getListQuest({});
          // Sync noty_setting
          oneSignalHandler(user?.data);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user?.data || {},
              typeStatuses,
              categories,
              categoriesDetail,
              cities,
              configs,
              siteAdPackages,
              secretKey,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              typeStatuses: [],
              categories: [],
              categoriesDetail: [],
              cities: [],
              configs: {},
              siteAdPackages: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            typeStatuses: [],
            categories: [],
            categoriesDetail: [],
            cities: [],
            configs: {},
            siteAdPackages: [],
          },
        });
      }
    };

    initialize();
  }, []);

  const getCommonCatalog = async () => {
    const categoriesDetail = [];
    const siteAdPackages = [];
    const configsResponse = await axios.get('api/v5/commons/configs/');

    const configs = configsResponse.data;
    let cities = configs?.cities;
    let typeStatuses = configs?.type_statuses;
    let categories = configs?.categories;

    if (typeStatuses?.length > 0) {
      typeStatuses = typeStatuses.map((type) => ({
        ...type,
        id: type?.id,
        label: type?.alias,
      }));
    }

    if (categories?.length > 0) {
      categories = categories.map((category) => ({
        ...category,
        label: category?.alias,
        children: category.children.map((categoryDetail) => ({
          ...categoryDetail,
          label: categoryDetail.alias,
          parentId: category.id,
        })),
      }));
      categories.forEach((category) => {
        if (category?.children) {
          const children = category?.children;
          children.forEach((child) => {
            categoriesDetail.push({
              id: child.id,
              label: child.alias,
              alias: child.alias,
              level: child.level,
              name: child.name,
              parentId: category.id,
            });
          });
        }
      });
    }

    if (cities?.length) {
      cities = cities.map((city) => ({
        ...city,
        id: city?.id,
        label: city?.alias,
      }));
    }
    return { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages };
  };

  const login = async (email, password, googleResponseCaptcha) => {
    const response = await axios.post('api/token/', {
      username: email,
      password,
      'g-recaptcha-response': googleResponseCaptcha,
    });
    const { access, refresh } = response.data;

    setSession(access, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/').catch((err) => console.error(err));
    const facebookDisabled = JSON.parse(process.env.REACT_APP_DISABLED_FACEBOOK);
    // Disable facebook actions
    if (facebookDisabled) {
      disableSocialName('facebook');
    }
    // Get pages,groups have connected
    await getConnectedsBySocial('facebook', {});
    // Get list quest
    await getListQuest({});
    oneSignalHandler(user?.data);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages,
      },
    });
  };

  const loginB2B = async (email, secretKey) => {
    const response = await axios.post('api/v1/b2b/authentication/login/', {
      username: email,
      secret_key: secretKey,
    });
    const { token, refresh } = response.data;
    localStorage.setItem('secretKey', secretKey);
    setSession(token, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/').catch((err) => console.error(err));
    // const facebookDisabled = JSON.parse(process.env.REACT_APP_DISABLED_FACEBOOK);
    // // Disable facebook actions
    // if (facebookDisabled) {
    //   disableSocialName('facebook');
    // }
    // // Get pages,groups have connected
    // await getConnectedsBySocial('facebook', {});
    // // Get list quest
    // await getListQuest({});
    // oneSignalHandler(user?.data);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages,
        secretKey,
      },
    });
  };

  const verify = async (accessToken, refreshToken) => {
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);
        const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } =
          await getCommonCatalog();
        const user = await axios.get('api/v1/users/me/');
        oneSignalHandler(user?.data);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user?.data || {},
            typeStatuses,
            categories,
            categoriesDetail,
            cities,
            configs,
            siteAdPackages,
          },
        });
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        const facebookDisabled = JSON.parse(process.env.REACT_APP_DISABLED_FACEBOOK);
        // Disable facebook actions
        if (facebookDisabled) {
          disableSocialName('facebook');
        }
        // Get pages,groups have connected
        await getConnectedsBySocial('facebook', {});
        // Get list quest
        await getListQuest({});
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            typeStatuses: [],
            categories: [],
            categoriesDetail: [],
            cities: [],
            configs: {},
            siteAdPackages: [],
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
          typeStatuses: [],
          categories: [],
          categoriesDetail: [],
          cities: [],
          configs: {},
          siteAdPackages: [],
        },
      });
    }
  };

  const googleLogin = async (code) => {
    console.log('call api sso');
    const response = await axios.post('api/sso/social/jwt-pair-user/google-oauth2/', {
      code,
      redirect_uri: window.location.href,
    });

    const { token, refresh } = response.data;

    setSession(token, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/');
    const facebookDisabled = JSON.parse(process.env.REACT_APP_DISABLED_FACEBOOK);
    // Disable facebook actions
    if (facebookDisabled) {
      disableSocialName('facebook');
    }
    // Get pages,groups have connected
    await getConnectedsBySocial('facebook', {});
    // Get list quest
    await getListQuest({});
    oneSignalHandler(user?.data);
    dispatch({
      type: 'GOOGLELOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages,
      },
    });
  };

  const facebookLogin = async (responsePrompt) => {
    // Base64 Encoded String
    const split = responsePrompt.signedRequest.split('.');
    const base64string = split[split.length - 1];
    // Creating the buffer object with utf8 encoding
    const bufferObj = Buffer.from(base64string, 'base64');
    // Decoding base64 into String
    const string = bufferObj.toString('utf8');
    // Printing the base64 decoded string
    const decodedSigned = JSON.parse(string);
    const response = await axios.post('api/sso/social/jwt-pair-user/facebook/', {
      code: decodedSigned.code,
      redirect_uri: `${window.location.protocol}//${window.location.host}/`,
    });

    const { token, refresh } = response.data;

    setSession(token, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/');
    oneSignalHandler(user?.data);
    dispatch({
      type: 'FACEBOOKLOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages,
      },
    });
  };

  // OneSignal listener notification
  const oneSignalHandler = async (user) => {
    await runOneSignal();
    const isSubscribed = await OneSignal.getSubscription();
    if (isSubscribed) {
      OneSignal.setExternalUserId(user?.email);
      // Storage info receive notifications
      OneSignal.getTags().then(async (tags) => {
        const { bds, report_daily: reportDaily, system, blog, update } = tags || {};
        const payload = new FormData();
        /**
         * bds, report_daily, system
         * |        |           |
         * V        V           V
         * blog,  update,      system
         */
        const selectedOptions = {
          update: reportDaily && reportDaily === '1' ? true : update === '1',
          system: system && system === '1' ? true : system === '1',
          blog: bds && bds === '1' ? true : blog === '1',
        };

        // Update tags on onesignal
        await OneSignal.sendTags({
          update: selectedOptions.update ? '1' : '0',
          system: selectedOptions.system ? '1' : '0',
          blog: selectedOptions.blog ? '1' : '0',
        });

        // Remove wrong tags if exist on onesignal
        if (bds || reportDaily) {
          await OneSignal.deleteTags(['bds', 'report_daily']);
        }

        payload.append('noty_option', JSON.stringify(selectedOptions));
        const config = {
          method: 'post',
          url: 'api/v1/users/me/',
          headers: { 'Content-Type': 'application/json' },
          data: payload,
        };
        axios(config);
      });
    }
  };

  const register = async (username, email, password, repassword, firstName, lastName) => {
    const response = await axios.post('api/v1/users/register/', {
      username,
      email,
      password1: password,
      password2: repassword,
      first_name: firstName,
      last_name: lastName,
    });
    const { access, user } = response.data;

    localStorage.setItem('accessToken', access);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    localStorage.removeItem('secretKey');
    setSession(null, null);
    resetSocialState();
    dispatch({ type: 'LOGOUT' });
  };

  const updateUserProfile = async () => {
    const user = await axios.get('api/v1/users/me/');
    dispatch({
      type: 'UPDATEPROFILE',
      payload: {
        user: user?.data || {},
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        googleLogin,
        facebookLogin,
        logout,
        register,
        verify,
        updateUserProfile,
        loginB2B,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
