import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function useWebView() {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    
    if (ios) {
      if (!standalone && !safari) {
        // iOS webview
        setIsWebView(true);
      }else{
        setIsWebView(false);
      }
    } else if (userAgent.indexOf('wv') !== -1 || userAgent.indexOf('zalo') !== -1) {
      // Android webview
      setIsWebView(true);
    } else {
      setIsWebView(false);
    }

  }, [])

  return {
    isWebView
  }
}
