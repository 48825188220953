// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  image_upload: {
    upload: 'Tải ảnh lên',
    delete: 'Xóa hết',
    update: 'Cập nhật ảnh',
  },
  pwa: {
    title: 'Tính năng nhận thông báo',
    step_one: `1. Bấm vào nút 'Chia sẻ'`,
    step_two: `2. Chọn 'Thêm vào màn hình chính'`,
    content: `
      1.Truy cập trang web của bạn trên trình duyệt Safari của bạn trên thiết bị di động Apple trên 16.4 trở lên.
      2.Nhấp vào nút Chia sẻ của trình duyệt Safari.
      3.Nhấp vào tùy chọn Thêm vào màn hình chính.
      4.Lưu ứng dụng trên thiết bị của bạn.
      5.Mở ứng dụng từ màn hình chính.
      6.Đăng ký nhận thông báo để sử dụng tính năng mới của chúng tôi.
    `,
  },
  progress: 'Đang trong tiến trình, xin đợi vài giây!',
  login: {
    main_title: 'Business Uptin',
    heading: 'Đăng nhập quản trị',
    sub_heading: 'Đăng nhập hoặc đăng ký nhanh với',
    by_email: 'Đăng nhập bằng email',
    remember: 'Nhớ  tài khoản',
    forgot: 'Quên mật khẩu',
    test_account: 'Tài khoản dùng thử : {{email}} / Mật khẩu : {{password}}',
    or: 'HOẶC',
    btn: {
      login: 'Đăng nhập',
    },
    form: {
      password: 'Mật khẩu',
    },
    message: {
      invalid: 'Email hoặc mật khẩu không đúng',
      suggest: 'Bạn có thể đăng nhập bằng Google thay cho Email và Mật Khẩu',
    },
  },
  forgot_password: {
    heading: 'Quên mật khẩu?',
    description:
      'Vui lòng nhập địa chỉ email được liên kết với tài khoản của bạn, chúng tôi sẽ gửi link hướng dẫn đặt lại mật khẩu.',
    form: {
      error: {
        email: {
          required: 'Email là bắt buộc',
          format: 'Phải nhập đúng định dạng email',
        },
      },
    },
    btn: {
      reset: 'Đặt lại mật khẩu',
      back: 'Quay lại',
    },
  },
  reset_password: {
    title: 'Yêu cầu đã gửi thành công!',
    form: {
      field: {
        password: 'Mật khẩu',
        re_password: 'Nhập lại mật khẩu',
      },
      error: {
        password: {
          required: 'Mật khẩu là bắt buộc',
        },
        re_password: {
          required: 'Nhập lại mật khẩu là bắt buộc',
          match: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    btn: {
      change: 'Đổi Mật Khẩu',
    },
  },
  nav: {
    general: `Tổng quan`,
    asset: `Tin`,
    calendar: `Lịch`,
    analytic: `Thị trường`,
    push_notification: `Báo`,
    blog: `Hóng`,
    create_asset: `Soạn tin`,
    real_estate: `Bất động sản`,
    social: `Mạng xã hội`,
    account: `Tài khoản`,
    setting: `Cài đặt`,
    payment: `Thanh toán`,
    change_password: `Đổi mật khẩu`,
    logout: `Đăng xuất`,
    detail: `Chi tiết`,
  },
  header: {
    notification: {
      notification: 'Thông báo',
      unread: `Bạn có # thông báo chưa đọc`,
      all_notification: 'Xem tất cả thông báo',
    },
  },
  component: {
    delete_account: {
      content: `ơi, điều gì xảy ra khi bạn "<span style="font-weight: bold">Xóa tài khoản</span>" ?`,
      note: {
        first: 'Uptin chỉ xóa tài khoản đăng nhập',
        second: 'Các tài khoản liên kết với các nền tảng vẫn giữ lại',
      },
      btn: {
        back: 'Quay lại',
        confirm: 'Xác nhận xóa',
      },
    },
  },
  dashboard: {
    app: {
      wellcome: 'Chào mừng bạn!',
      content: 'Chọn đăng tin hoặc làm nhiệm vụ để nhận tiền FREE nhé.',
      btn: {
        post: 'Đăng tin ngay',
        quest: 'Nhiệm vụ',
        click: 'Lượt click',
        ctr: 'CTR',
        cpm: 'CPM',
        view_all: 'Xem toàn bộ',
      },
      tab: {
        day: 'Ngày',
        week: 'Tuần',
        month: 'Tháng',
      },
      chart: {
        total_post: 'Tổng tin',
        view: 'Lượt xem',
        display: 'Lượt hiển thị',
        statistics: 'Tổng tin theo',
        statistics_sub: 'so với {{timeRange}} trước',
        statistics_platform: 'Thống kê tin đăng theo nền tảng',
        schedule_success: 'Tin đăng thành công',
        schedule_fail: 'Tin lỗi',
        balance: 'Số dư hiện tại',
        payment: 'Nạp',
        disburse: 'Chi tiêu',
        real_estate: 'BĐS',
        social: 'MXH',
        month: 'tháng',
        week: 'tuần',
        site: 'Trang',
        spend: 'Tổng chi tiêu',
        staff: 'Nhân viên',
      },
      table: {
        head: {
          avatar_name: 'Avatar/Tên',
          posted_success: 'Tin thành công',
          posted_fail: 'Tin lỗi',
          post_real_estate: 'Tin BĐS',
          post_social: 'Tin MXH',
          spend_limit: 'Chi/Hạn mức',
          reach: 'Lượt tiếp cận',
          view: 'Lượt xem',
          click: 'Lượt click',
          ctr: 'CTR',
          cpm: 'CPM',
        },
      },
    },
    account: {
      save: 'Lưu thay đổi',
      breadcrumb: {
        home: 'Trang chủ',
        user: 'Người dùng',
        setting: 'Cài đặt',
      },
      tab: {
        general: 'Thông tin chung',
        payment: 'Thanh toán',
        notification: 'Thông báo',
        social: 'Mạng xã hội',
        password: 'Mật khẩu',
        rule: 'Cấp phép quyền',
      },
      general: {
        avatar: {
          upload: 'Tải ảnh lên',
          update_type: 'Cho phép ảnh đuôi *.jpeg, *.jpg, *.png',
          update_size: 'Dung lượng tối đa 1Mb',
        },
        public: 'Công khai hồ sơ',
        delete: 'Xóa tài khoản',
        display: 'Hiển thị',
        color: 'Màu',
        feature: 'Tính năng',
        route: 'Truy cập',
        form: {
          first_name: 'Tên',
          last_name: 'Họ',
          phone: 'Điện thoại di động',
          city: 'Tỉnh/Thành phố',
          district: 'Quận/Huyện',
          company: 'Công ty',
          position: 'Chức vụ',
          social_enable: 'Không đăng nhập bằng mạng xã hội',
          primary_color: 'Màu chủ đạo',
          secondary_color: 'Màu phụ',
          background_color: 'Màu nền',
          text_color: 'Màu chữ',
          accent_color: 'Màu bổ sung',
        },
        features: {
          payment: 'Cho phép nạp tiền',
          change_password: 'Cho phép thay đổi mật khẩu',
          'asset@create': 'Cho phép tạo tin',
          'asset@edit': 'Cho phép chỉnh sửa tin',
          'schedule@bds': 'Cho phép lên lịch đăng bài bất động sản',
          'schedule@mxh': 'Cho phép lên lịch đăng bài mạng xã hội',
          'site@add': 'Cho phép thêm tài khoản trang web',
          'site@edit': 'Cho phép chỉnh sửa tài khoản trang web',
          'social@link': 'Cho phép liên kết tài khoản xã hội',
          gpt: 'Cho phép sử dụng các tính năng tích hợp AI',
          'schedule@web': 'Cho phép lên lịch đăng bài website',
          'asset@clone': 'Cho phép tạo ra bài mới dựa trên bài có sẵn',
        },
        routes: {
          account: 'Truy cập vào trang tài khoản',
          calendar: 'Truy cập vào trang lịch',
          notification: 'Truy cập trang danh sách thông báo',
          notification_create: 'Truy cập trang "Báo"',
          post_list: 'Truy cập trang quản lý tin',
          quest: 'Truy cập vào trang nhiệm vụ',
          real_create: 'Truy cập vào trang tạo tin bất động sản',
          real_edit: 'Truy cập trang chỉnh sửa tin bất động sản',
          social_create: 'Truy cập vào trang tạo tin xã hội',
          social_edit: 'Truy cập trang chỉnh sửa tin xã hội',
        },
        success: 'Cập nhật thành công!',
        error: 'Cập nhật không thành công!',
        delete_account: 'Xóa tài khoản thành công! Hệ thống sẽ chuyển về trang đăng nhập trong vài giây!',
      },
      payment: {
        recharge: 'Nạp',
        payment: 'Thanh toán',
        method: 'Hình thức thanh toán',
        wallet: 'Ví',
        history: 'Lịch sử mua dịch vụ',
        empty: 'Không có',
        add_method: 'Thêm cách thanh toán',
        topup: 'Nạp',
        proceed: 'Thanh toán',
      },
      notifications: {
        activity: {
          comment: 'Gửi email cho tôi khi ai đó nhận xét bài viết của tôi',
          feeling: 'Gửi email cho tôi khi ai đó bày tỏ cảm xúc bài viết của tôi',
          follow: 'Gửi email cho tôi khi có người theo dõi tôi',
        },
        application: {
          system: 'Tin tức và thông báo hệ thống',
          update: 'Các bản cập nhật phần mềm hàng tuần',
          blog: 'Các bài viết blog mới',
        },
        success: 'Cập nhật thành công!',
      },
      change_password: {
        old_password: 'Mật khẩu cũ',
        new_password: 'Mật khẩu mới',
        re_new_password: 'Nhập lại mật khẩu mới',
        error: {
          old_password_required: 'Mật khẩu cũ là bắt buộc',
          new_password_required: 'Mật khẩu mới là bắt buộc',
          new_password_length: 'Mật khẩu mới phải ít nhất 6 ký tự',
          re_new_password: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    analytic: {
      location: 'Vị trí',
      breadcrumb: {
        market: 'Thị trường',
        overview: 'Tổng quan',
      },
      form: {
        city: 'Tỉnh/Thành phố',
        district: 'Quận/Huyện',
        ward: 'Phường/Xã',
        error: {
          required: 'Vui lòng điền vào đây',
        },
      },
      btn: {
        search: 'Tra cứu thông tin',
      },
      tab: {
        sell: 'Bán',
        rent: 'Thuê',
        facade: 'Mặt tiền',
        alley: 'Trong hẻm',
      },
      chart: {
        compare: 'so với tháng trước',
        title: 'Thị trường trong 3 năm',
        average_price: 'Giá trung bình',
        number_posts: 'Lượng tin',
      },
      average_price: 'Giá trung bình',
      number_posts: 'Số lượng tin',
      compare: 'so với quý trước',
      helps: 'Phân tích giúp bạn',
      develop: 'Tính năng này đang trong quá trình phát triển',
    },
    asset: {
      breadcrumb: {
        asset: 'Tin',
        overview: 'Tổng quan',
      },
      stat: {
        success: 'Thành công',
        scheduled: 'Lên lịch',
        total: 'Tổng tin',
        month: 'tin trong tháng',
        sum: 'tổng số tin',
      },
      btn: {
        remove_filter: 'Bỏ lọc',
        real_estate: 'BĐS',
        social: 'MXH',
        edit: 'Chỉnh sửa',
        schedule: 'Đăng tin',
        detail: 'Chi tiết',
      },
      filter: {
        type: 'Loại tin',
        start_day: 'Ngày bắt đầu',
        end_day: 'Ngày kết thúc',
        search: 'Tìm theo tiêu đề',
      },
      table: {
        head: {
          type: 'Loại tin',
          avatar: 'Hình đại diện',
          title: 'Tiêu đề',
          city: 'Tỉnh thành phố',
          created_day: 'Ngày tạo',
          updated_day: 'Cập nhật',
        },
      },
      detail: {
        title: 'Chi tiết tin',
        table: {
          head: {
            platform: 'Nền tảng',
            title: 'Tiêu đề',
            date: 'Ngày',
            display: 'Hiển thị',
            status: 'Tình trạng',
            view: 'Lượt xem',
            impression: 'Lượt tiếp cận',
          },
        },
        tab: {
          all: 'Tổng',
          active: 'Đang hiển thị',
          inactive: 'Chưa thanh toán',
          hidden: 'Đang ẩn tin',
          remove: 'Đã xóa',
        },
        btn: {
          back: 'Quay lại',
          link: 'Xem tin',
        },
        dialog: 'Lọc tin',
        notification: 'Thông báo',
        collapse: 'Thu gọn',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            id_balance: 'ID/Số dư',
            select_package: 'Chọn gói',
            title_balance: 'Tiêu đề/Số dư',
            note: 'Ghi chú',
            package: 'Gói',
            date_post: 'Ngày đăng',
            day_post: 'Chọn ngày',
            time_post: 'Chọn giờ',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            your_page: 'Trang của bạn',
            your_group: 'Nhóm của bạn',
            other_group: 'Nhóm của người khác',
          },
        },
        btn: {
          edit: 'Chỉnh sửa',
          back: 'Quay lại',
          done: 'Hoàn thành',
        },
        tab: {
          real_estate: 'Bất động sản',
          social: 'Mạng xã hội',
        },
        current_asset: 'Tin đang chọn',
        spam: 'Chúng tôi thêm 5 phút độ trễ giữa các tin để bạn không bị đánh dấu spam',
        collapse: 'Thu gọn',
        type: 'Loại tin',
      },
      breadcrumb: {
        home: 'Trang chủ',
        calendar: 'Lịch',
      },
      btn: {
        schedule: 'Lên lịch',
      },
      title: {
        dialog: 'Lọc tin',
        notification: 'Thông báo',
      },
    },
    site: {
      action: {
        link_account: 'liên kết tài khoản BĐS',
      },
      breadcrumb: {
        home: 'Trang chủ',
        account: 'Tài khoản',
      },
      btn: {
        add: 'Thêm',
        real_estate: 'BĐS',
        social: 'MXH',
        collapse: 'Thu gọn',
        view: 'Xem',
        edit: 'Chỉnh sửa',
        connect: 'Kết nối',
        close: 'Đóng',
        save: 'Kiểm tra và lưu',
      },
      tab: {
        all: 'Tổng',
        real_estate: 'Website BĐS',
        social: 'Mạng xã hội',
      },
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            id_balance: 'ID/Số dư',
            name: 'Tên tài khoản',
            status: 'Tình trạng',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            connected: 'Đã kết nối',
            disconnect: 'Mất kết nối',
          },
        },
        field: {
          password: 'Mật khẩu',
          username: 'Tài khoản',
          is_branch: 'Tài khoản phụ/Doanh nghiệp',
        },
        error: {
          required: 'Vui lòng nhập vào đây',
        },
      },
    },
    social: {
      platform: 'Chọn nền tảng liên kết',
      message: {
        empty_page: 'Bạn chưa tham gia bất kỳ trang nào',
        empty_group: 'Bạn chưa tham gia bất kỳ nhóm nào',
        empty_installed: `Không tìm thấy thông tin về các nhóm đã thêm app Uptin`,
      },
      tab: {
        fanpage: 'Trang',
        own_group: 'Nhóm của bạn',
        installed_group: 'Nhóm đã kết nối với Uptin',
      },
      btn: {
        prev: 'Quay lại',
        next: 'Tiếp theo',
        back: 'Quay lại',
        copy_link: 'Copy LINK nhóm',
      },
      form: {
        search: 'Tìm theo tên nhóm...',
      },
      snackbar: {
        connected: 'Kết nối thành công',
        copied: 'Đã copy đường dẫn!',
      },
      popup: {
        content: `Để đăng được lên nhóm “<span style="font-weight: bold">#</span>”, bạn cần liên hệ với Quản trị viên nhóm và yêu cầu họ <span style="font-weight: bold">thêm ứng dụng Uptin</span> trong phần App của nhóm. Hãy tìm nhóm trên facebook hoặc copy link này và paste trên trình duyệt.`,
      },
    },
    create_asset: {
      breadcrumb: {
        home: 'Trang chủ',
        asset: 'Tin',
        create_asset: 'Soạn tin',
        edit_asset: 'Chỉnh sửa',
      },
      real_estate: {
        title_create: 'Soạn tin bất động sản',
        title_edit: 'Chỉnh sửa tin bất động sản',
        tab: {
          general: 'Thông tin cơ bản',
          detail: 'Thông tin mô tả',
        },
        btn: {
          next_step: 'Tiếp tục',
          complete: 'Hoàn thành',
          amenitie: 'Tiện ích xung quanh',
          convince: 'Thuyết phục',
          back: 'Quay lại',
          close: 'Đóng',
          add_amenitie: 'Thêm vào nội dung',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
        },
        form: {
          field: {
            asset_type: 'Loại hình bất động sản',
            asset_detail: 'Bất động sản chi tiết',
            city: 'Tỉnh/Thành phố',
            district: 'Quận/Huyện',
            ward: 'Phường/Xã',
            street: 'Đường/Phố',
            price: 'Giá(VNĐ)',
            area: 'Diện tích (m2)',
            actual_area: 'Diện tích sử dụng (m2)',
            demand: {
              title: 'Nhu cầu*',
              sell: 'Cần bán',
              rent: 'Cho thuê',
              buy: 'Cần mua',
              employ: 'Cần thuê',
            },
            who: {
              title: 'Bạn là*',
              broker: 'Môi giới',
              owner: 'Chính chủ',
              developer: 'Nhà phát triển',
              investor: 'Nhà đầu tư',
            },
            bedroom: 'Phòng ngủ',
            toilet: 'Toilet',
            legal_document: 'Giấy tờ pháp lý',
            contact_info: 'Thông tin liên hệ',
            address: 'Số nhà',
            hand_overs: {
              project: 'Dự án',
              under_construction: 'Đang xây dựng',
              slacking: 'Chưa xong',
              handing_over: 'Đang bàn giao',
              complete: 'Hoàn thành',
              red_book: 'Sổ đỏ',
              house_ownership: 'Sổ hồng',
              sale_contract: 'Hợp đồng mua bán',
              valid_documents: 'Giấy tờ hợp lệ',
            },
            title: 'Tiêu đề',
            short_title: 'Ngắn (30-50 ký tự)',
            long_title: 'Dài (45-90 ký tự)',
            description: 'Mô tả chi tiết (tối đa 1500 ký tự)...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tối thiểu 3 ảnh-Tối đa 6 ảnh',
              first_content: '-Kéo thả hoặc nhấn chọn ảnh',
              second_content: '-Dung lượng ảnh tối đa 3Mb',
              third_content: '-Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
          },
          error: {
            select: 'Vui lòng chọn',
            required: 'Vui lòng điền vào đây',
            price: 'Vui lòng nhập giá đúng',
            area: 'Vui lòng nhập diện tích đúng',
            short_title: {
              min: 'Tiêu đề tối thiểu 30 ký tự',
              max: 'Tiêu đề ngắn tối đa 50 ký tự',
            },
            long_title: {
              min: 'Tiêu đề tối thiểu 45 ký tự',
              max: 'Tiêu đề dài tối đa 90 ký tự',
            },
            description: {
              min: 'Mô tả chi tiết tối thiểu 200 ký tự',
              max: 'Mô tả chi tiết tối đa 1500 ký tự',
            },
            images: {
              min: 'Vui lòng tải lên tối thiểu 3 ảnh',
              max: 'Vui lòng tải lên tối đa 6 ảnh',
            },
          },
        },
      },
      social: {
        title_create: 'Soạn tin mạng xã hội',
        title_edit: 'Chỉnh sửa tin mạng xã hội',
        btn: {
          rewrite: 'Viết lại',
          complete: 'Hoàn thành',
          google_format: 'Định dạng bài Google',
          instagram_format: 'Định dạng bài Instagram',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
          hashtag_error: 'Thêm một số từ khóa liên quan đến vấn đề của bạn',
        },
        form: {
          field: {
            keyword: 'Từ khóa, cách nhau bởi dấu phẩy',
            description: 'Hãy điền gì đó thật thú vị...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tải lên tối đa 6 ảnh',
              first_content: '-Kéo thả hoặc nhấn chọn ảnh',
              second_content: '-Dung lượng ảnh tối đa 3Mb',
              third_content: '-Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
          },
          error: {
            required: 'Vui lòng điền vào đây',
            description: {
              min: 'Điền ít nhất 50 ký tự',
              invalid: 'Nội dung phải nhiều hơn 50 ký tự (không tính biểu tượng cảm xúc)',
            },
            images: {
              max: 'Vui lòng tải lên tối đa 6 ảnh',
            },
          },
        },
      },
    },
    notification: {
      you: 'Bạn',
      breadcrumb: {
        general: 'Tổng quan',
        notification: 'Thông báo',
        all_notification: 'Tất cả thông báo',
      },
      menu: {
        setting: 'Cài đặt thông báo',
        mark_read: 'Đánh dấu tất cả là đã đọc',
      },
      btn: {
        collapse: 'Thu gọn',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Nhiệm vụ',
        general: 'Tổng quan',
        user: 'Người dùng',
      },
      detail: 'Chi tiết nhiệm vụ:',
      multi: 'số tiền',
      vnd: 'đ',
      members: 'T.viên',
      title: {
        reward_login_daily: 'Đăng nhập hằng ngày',
        reward_daily_scheduled_bds: 'Đăng 1 tin BĐS',
        reward_daily_scheduled_mxh: 'Đăng 1 tin MXH',
        reward_linking_account: 'Liên kết thành công 1 tài khoản',
        promo_linking_facebook_group: 'Thêm Uptin vào nhóm Facebook',
        promo_daily_topup: 'Nạp lần đầu trong ngày',
        promo_first_topup: 'Nạp lần đầu',
      },
      description: {
        reward_login_daily:
          '- Phần thưởng: 5.000 đ\n- Yêu cầu: Đăng nhập vào Uptin thông qua Google hoặc Facebook\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_bds:
          '- Phần thưởng:  2.000 đ\n- Yêu cầu: Lên lịch cho 01 tin BĐS thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_mxh:
          '- Phần thưởng:  2.000 đ\n- Yêu cầu: Lên lịch cho 01 tin MXH thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_linking_account:
          '- Phần thưởng: 5.000 đ\n- Yêu cầu: Kết nối 1 tài khoản BĐS/MXH lần đầu tiên vào Uptin và thành công.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_linking_facebook_group:
          '- Phần thưởng: 10đ x Số lượng thành viên nhóm\n- Yêu cầu: Là người kết nối đầu tiên với nhóm đã cài đặt ứng dụng Uptin trong hệ thống. Nhóm phải có ít nhất 100 thành viên.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_daily_topup:
          '- Phần thưởng:  x2 số tiền nạp (Thưởng tối đa 50.000 đ)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Mỗi ngày',
        promo_first_topup:
          '- Phần thưởng: x2 số tiền nạp (không giới hạn)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Một lần duy nhất',
      },
      status: {
        completed: 'Hoàn thành',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Thông báo',
        overview: 'Tổng quan',
        create_notification: 'Tạo thông báo',
      },
      tab: {
        list: 'Danh sách',
        approve: 'Phê duyệt',
        audience: 'Đối tượng',
        content: 'Nội dung',
      },
      table: {
        head: {
          image: 'Ảnh',
          title: 'Tiêu đề',
          status: 'Tình trạng',
          budget: 'Ngân sách <br /> người nhận',
          date_send: 'Ngày gửi',
          content: 'Nội dung',
        },
      },
      form: {
        label: {
          demographic: 'Nhân khẩu học',
          date_picker: 'Chọn ngày',
        },
        field: {
          area: {
            title: 'Khu vực',
            northern: 'Miền Bắc',
            central: 'Miền Trung',
            southern: 'Miền Nam',
          },
          city: 'Tỉnh/Thành phố',
          career: {
            title: 'Nghề nghiệp',
            broker: 'Môi giới',
            owner: 'Chính chủ',
            developer: 'Nhà phát triển',
            investor: 'Nhà đầu tư',
          },
          asset_type: 'Loại hình bất động sản',
          asset_detail: 'Bất động sản chi tiết',
          demand: {
            title: 'Chuyên',
            sell: 'Cần bán',
            rent: 'Cho thuê',
            buy: 'Cần mua',
            employ: 'Cần thuê',
          },
          all: 'Tất cả',
          image: {
            heading: 'Tải ảnh lên',
            first_content: '-Kéo thả hoặc nhấn chọn ảnh',
            second_content: '-Dung lượng ảnh tối đa 5Mb',
            third_content: '-Ảnh có kích thước 256x256 px',
          },
          title: 'Tiêu đề(6-80 ký tự)',
          content: 'Nội dung(10-150 ký tự)',
          link: 'Đường link thông báo',
        },
        error: {
          required: 'Vui lòng điền vào đây',
          image: {
            required: 'Vui lòng tải ảnh lên',
            only_one: 'Vui lòng tải lên 1 ảnh',
            format: 'Ảnh phải có kích thước 256x256 px',
          },
          title: {
            min: 'Nhập ít nhất 6 ký tự',
            max: 'Nhập tối đa 80 ký tự',
          },
          content: {
            min: 'Nhập ít nhất 10 ký tự',
            max: 'Nhập tối đa 150 ký tự',
          },
          link: {
            format: 'Vui lòng nhập đúng định dạng link',
          },
        },
      },
      preview: 'Xem trước',
      btn: {
        delete: 'Xóa',
        approve: 'Duyệt',
        reject: 'Từ chối',
        collapse: 'Thu gọn',
        create_notification: 'Tạo thông báo',
        next: 'Tiếp tục',
        back: 'Quay lại',
        complete: 'Hoàn thành',
      },
      status: {
        deliveried: 'Đã phân phối',
        canceled: 'Từ chối',
        pending: 'Chờ duyệt',
        scheduled: 'Lên lịch',
      },
      message: {
        create_success: 'Tạo thông báo thành công',
        deleted_success: 'Xóa thông báo thành công',
        deleted_fail: 'Xóa thông báo thất bại',
        approved_success: 'Phê duyệt thông báo thành công',
        approved_fail: 'Phê duyệt thông báo thất bại',
        denied_success: 'Từ chối thông báo thành công',
        denied_fail: 'Từ chối thông báo thất bại',
      },
    },
  },
  translate_catalog: {
    city: ['Thành phố', 'Tỉnh'],
    district: ['Thành phố', 'Phường', 'Huyện', 'Thị xã', 'Quận'],
    ward: ['Thị trấn', 'Xã', 'Phường'],
    street: ['Đường', 'Phố', 'Tỉnh lộ', 'duong', 'Quốc Lộ', 'Đại Lộ'],
    demands: ['Cần bán', 'Cho thuê', 'Cần mua', 'Cần thuê'],
    categories: {
      apartment: {
        title: 'Căn hộ/Chung cư',
        apartment: 'Chung cư',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: 'Căn hộ dịch vụ, mini',
        housing_project: 'Tập thể, cư xá',
        officetel: 'Officetel',
      },
      house: {
        title: 'Nhà ở',
        town_house: 'Nhà mặt phố, mặt tiền',
        alley_house: 'Nhà ngõ, hẻm',
        villa: 'Nhà biệt thự',
        terrace_house: 'Nhà phố liền kề',
      },
      land: {
        title: 'Đất',
        industrial_land: 'Đất công nghiệp',
        agricultural_land: 'Đất nông nghiệp',
        residential_land: 'Đất thổ cư',
        ground_project: 'Đất nền dự án',
      },
      commercial: {
        title: 'Văn phòng, Mặt bằng kinh doanh',
        business_premises: 'Mặt bằng kinh doanh',
        office: 'Văn phòng',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Phòng trọ',
        rooming_house: 'Phòng trọ',
      },
    },
  },
  today: 'Hôm nay',
  empty_data: 'Không có dữ liệu',
  version: 'Phiên bản',
  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `chỉnh sửa`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `tin`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách tin`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level_1: `menu cấp 1`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
};

export default vn;
