import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fDateTime } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useDispatch, useSelector } from '../../../redux/store';
import { getNotifications, markNotiAsRead } from '../../../redux/slices/notification';
import useAuth from '../../../hooks/useAuth';

// hooks
import useLocales from '../../../hooks/useLocales';
// ----------------------------------------------------------------------

const LIMIT_NOTY_NUMBER = 10;
const NOTY_SCHEDULING_POST_TYPE = 'snackbar_to_client';

export default function NotificationsPopover() {
  const { translate } = useLocales('header.notification');

  const dispatch = useDispatch();

  const { bellNotiList, totalUnread } = useSelector((state) => state.noti);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => dispatch(markNotiAsRead([]));

  const showAllNoti = () => {
    handleClose();
    navigate(PATH_DASHBOARD.user.notification);
  };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnread} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          overflowY: 'auto',
          height: bellNotiList.length > LIMIT_NOTY_NUMBER / 2 ? '73%' : 'fit-content',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notification')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('unread').replace('#', totalUnread)}
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title="Đánh dấu tất cả là đã đọc">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}

          {/* <Tooltip title="Tải lại">
            <IconButton color="primary" onClick={reloadNotiList}>
              <Iconify icon="material-symbols:refresh" width={20} height={20} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <List disablePadding>
          {bellNotiList.map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </List>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth onClick={showAllNoti}>
            {translate('all_notification')}
          </Button>
        </Box>
        <></>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  id: PropTypes.number,
  timestamp: PropTypes.instanceOf(Date),
  verb: PropTypes.string,
  target_name: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.shape({
    is_read: PropTypes.bool,
    uuid: PropTypes.string,
  }),
};

function NotificationItem({ notification, getNotiId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const { avatar, title } = renderContent(notification, user);

  const onClickNoty = async (event, notyId) => {
    // if(notification.data.hasOwnProperty.call("uuid")) {
    //   setTimeout(() => {
    //     dispatch(markNotiAsRead([notyId]))
    //   }, 200)
    //   return navigate(PATH_DASHBOARD.post.list, { state: { uuid: notification.data.uuid } })
    // }

    if (notification.data.is_read || Object.prototype.hasOwnProperty.call(notification.data, 'expiration')) {
      return '';
    }

    return dispatch(markNotiAsRead([notyId]));
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...((!notification.data.is_read || Object.prototype.hasOwnProperty.call(notification.data, 'expiration')) && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={(event) => onClickNoty(event, notification.id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fDateTime(new Date(notification.timestamp))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification, user) {
  const getUserInfo = () => {
    let description = '';
    description = notification?.target_name ? (
      <>
        {highlightNotyStatus(notification?.verb)}{' '}
        <Typography display="inline" variant="subtitle2">
          {notification?.target_name}
        </Typography>
      </>
    ) : (
      _.lowerFirst(notification?.description)
    );

    if (!notification?.target_id && !notification?.target_name && !notification?.description) {
      description = notification?.verb;
    }
    if (notification?.actor_name === user?.name || notification?.actor_name === user?.email) {
      return {
        name: 'Bạn',
        avatar: user?.image,
        description,
      };
    }
    return {
      name: _.get(notification, 'actor_name', ''),
      avatar: _.get(notification, 'data.image', ''),
      description,
    };
  };

  const title = (
    <Typography variant="subtitle2">
      {getUserInfo().name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp;{getUserInfo().description}
      </Typography>
    </Typography>
  );

  return {
    avatar: <img alt={notification.title} src={getUserInfo().avatar} />,
    title,
  };
}

const highlightNotyStatus = (text) => {
  let temp = '';
  let hightlightPart = '';
  const SUCCESS = {
    color: '#54D62C',
    text: 'lên lịch',
  };
  const FAILED = {
    color: '#FF4842',
    text: 'thất bại',
  };

  if (text.indexOf(SUCCESS.text) !== -1 && text.indexOf(FAILED.text) === -1) {
    temp = text.split(SUCCESS.text);
    hightlightPart = <span style={{ color: SUCCESS.color }}>{SUCCESS.text}</span>;
  } else if (text.indexOf(FAILED.text) !== -1) {
    temp = text.split(FAILED.text);
    hightlightPart = <span style={{ color: FAILED.color }}>{FAILED.text}</span>;
  } else return text;

  return (
    <>
      {_.first(temp)} {hightlightPart} {_.last(temp)}
    </>
  );
};
