import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  isNumeric: PropTypes.bool,
};

export default function RHFTextField({ name, isNumeric = false, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
        // console.log("other", other);
        if (isNumeric) {
          return (
            <NumericFormat
              onBlur={onBlur} // notify when input is touched
              inputRef={ref}
              fullWidth
              value={typeof value === 'number' && Number(value) === 0 ? '' : Number(value)}
              customInput={TextField}
              onValueChange={({ value: v }) => {
                if(v === '') {
                  onChange(0);
                } else {
                  onChange(Number(v));
                }
              }}
              error={!!error}
              helperText={error?.message}
              {...other}
            />
          )
        }
        return (
          <TextField
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            inputRef={ref}
            fullWidth
            value={typeof value === 'number' && Number(value) === 0 ? '' : value}
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        )
      }}
    />
  );
}
