// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  image_upload: {
    upload: 'Upload here',
    delete: 'Delete all',
    update: 'Update image',
  },
  pwa: {
    title: 'Notification Feature',
    step_one: `1. Click the 'Share' button`,
    step_two: `2. Select 'Add to Homescreen'`,
    content: `
      1.Access your website updates on your Safari browser on Apple mobile devices 16.4 or later.
      2.Click the Share button on Safari browser.
      3.Click the Add to Home screen option.
      4.Save the app on your device.
      5.Open the app from the home screen.
      6.Sign up for notifications to use our new feature.
    `,
  },
  progress: 'In progress, please wait a few seconds!',
  login: {
    main_title: 'Business Uptin',
    heading: 'Admin login',
    sub_heading: 'Log in or register with',
    by_email: 'Login with email',
    remember: 'Remember me',
    forgot: 'Forgot password',
    test_account: 'Demo account: {{email}} / Password: {{password}}',
    or: 'OR',
    btn: {
      login: 'Login',
    },
    form: {
      password: 'Password',
    },
    message: {
      invalid: 'Email or password is incorrect',
      suggest: 'You can sign in with Google instead of Email and Password',
    },
  },
  forgot_password: {
    heading: 'Forgot password?',
    description:
      'Please enter the email address associated with your account, we will send a link to reset your password.',
    form: {
      error: {
        email: {
          required: 'Email is required',
          format: 'Must enter the correct email format',
        },
      },
    },
    btn: {
      reset: 'Reset Password',
      back: 'Back',
    },
  },
  reset_password: {
    title: 'Request sent successfully!',
    form: {
      field: {
        password: 'Password',
        re_password: 'Re-password',
      },
      error: {
        password: {
          required: 'Password is required',
        },
        re_password: {
          required: 'Re-entering password is required',
          match: 'Re-entered password does not match',
        },
      },
    },
    btn: {
      change: 'Change Password',
    },
  },
  nav: {
    general: `Dashboard`,
    asset: `Content`,
    calendar: `Calendar`,
    analytic: `Market`,
    push_notification: `Push notification`,
    create_asset: `Create content`,
    blog: `Blog`,
    real_estate: `Real estate`,
    social: `Social media`,
    account: `Account`,
    setting: `Setting`,
    payment: `Payment`,
    change_password: `Change password`,
    logout: `Logout`,
    detail: `Detail`,
  },
  header: {
    notification: {
      notification: 'Notification',
      unread: `You have # unread notifications`,
      all_notification: 'View all notifications',
    },
  },
  component: {
    delete_account: {
      content: `, what happens when you "<span style="font-weight: bold">Delete account</span>" ?`,
      note: {
        first: 'Uptin only deletes login accounts',
        second: 'Accounts linked to platforms still retain',
      },
      btn: {
        back: 'Back',
        confirm: 'Confirm delete',
      },
    },
  },
  dashboard: {
    app: {
      wellcome: 'Wellcome!',
      content: 'Choose to post or do tasks to receive FREE money.',
      btn: {
        post: 'Post now',
        quest: 'Daily tasks',
        click: 'Clicks',
        ctr: 'CTR',
        cpm: 'CPM',
        view_all: 'See all',
      },
      tab: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
      },
      chart: {
        total_post: 'Total post',
        view: 'View',
        display: 'Impressions',
        statistics: 'Total posts by',
        statistics_sub: 'than last {{timeRange}}',
        statistics_platform: 'Statistics of posts by platform',
        schedule_success: 'Successful post',
        schedule_fail: 'Failed post',
        balance: 'Balance',
        payment: 'Top-up',
        disburse: 'Disburse',
        real_estate: 'Real estate',
        social: 'Social media',
        month: 'month',
        week: 'week',
        site: 'Sites',
        spend: 'Total spending',
        staff: 'Staff',
      },
      table: {
        head: {
          avatar_name: 'Avatar/Name',
          posted_success: 'Success posts',
          posted_fail: 'Error posts',
          post_real_estate: 'Posts real estate',
          post_social: 'Posts social media',
          spend_limit: 'Spend/Limit',
          reach: 'Reach',
          view: 'Views',
          click: 'Clicks',
          ctr: 'CTR',
          cpm: 'CPM',
        },
      },
    },
    account: {
      save: 'Save changes',
      breadcrumb: {
        home: 'Dashboard',
        user: 'User',
        setting: 'Setting',
      },
      tab: {
        general: 'General',
        payment: 'Payment',
        notification: 'Notification',
        social: 'Social Media',
        password: 'Password',
        rule: 'Rules',
      },
      general: {
        avatar: {
          upload: 'Upload here',
          update_type: '*.jpeg, *.jpg, *.png . images allowed',
          update_size: 'Maximum capacity 1Mb',
        },
        public: 'Public profile',
        delete: 'Delete account',
        display: 'Display',
        color: 'Colors',
        feature: 'Features',
        route: 'Routes',
        form: {
          first_name: 'First name',
          last_name: 'Last name',
          phone: 'Phone number',
          city: 'City',
          district: 'District',
          company: 'Company',
          position: 'Position',
          social_enable: 'Do not login with social network',
          primary_color: 'Primary color',
          secondary_color: 'Secondary color',
          background_color: 'Background color',
          text_color: 'Text color',
          accent_color: 'Accent color',
        },
        features: {
          payment: 'Allow to top up',
          change_password: 'Allow to change password',
          'asset@create': 'Allow to create post',
          'asset@edit': 'Allow to edit post',
          'schedule@bds': 'Allow to schedule real estate post',
          'schedule@mxh': 'Allow to schedule social media post',
          'site@add': 'Allow to add new site account',
          'site@edit': 'Allow to edit site account',
          'social@link': 'Allow to link social account',
          gpt: 'Allow to use features integrate AI',
          'schedule@web': 'Allow to schedule website post',
          'asset@clone': 'Allow to clone exist post',
        },
        routes: {
          account: 'Access to the accounts page',
          calendar: 'Access to the calendar page',
          notification: 'Access to the notification page',
          notification_create: 'Access to the push notification page',
          post_list: 'Access to the post page',
          quest: 'Access to the quest page',
          real_create: 'Access to the real estate create page',
          real_edit: 'Access to the real estate edit page',
          social_create: 'Access to the social create page',
          social_edit: 'Access to the social edit page',
        },
        error: 'Update error!',
        success: 'Update successful!',
        delete_account: 'Account deletion succeeded! The system will return to the login page in a few seconds!',
      },
      payment: {
        recharge: 'Recharge',
        payment: 'Payment',
        method: 'Payment methods',
        wallet: 'Wallet',
        history: 'History',
        empty: 'Empty',
        add_method: 'Add new method payment',
        topup: 'Top-up',
        proceed: 'Proceed',
      },
      notifications: {
        activity: {
          comment: 'Email me when someone comments on my post',
          feeling: 'Email me when someone reacts to my post',
          follow: 'Email me when someone follows me',
        },
        application: {
          system: 'System news and announcements',
          update: 'Weekly software updates',
          blog: 'New blog posts',
        },
        success: 'Update completed!',
      },
      change_password: {
        old_password: 'Current password',
        new_password: 'New password',
        re_new_password: 'Repeat new password',
        error: {
          old_password_required: 'Old password is required',
          new_password_required: 'New password is required',
          new_password_length: 'New password must be at least 6 characters',
          re_new_password: 'Re-entered password does not match',
        },
      },
    },
    analytic: {
      location: 'Location',
      breadcrumb: {
        market: 'Market',
        overview: 'Dashboard',
      },
      form: {
        city: 'City',
        district: 'District',
        ward: 'Ward',
        error: {
          required: 'Please fill in here',
        },
      },
      btn: {
        search: 'Analytic',
      },
      tab: {
        sell: 'Sell',
        rent: 'Rent',
        facade: 'Facade',
        alley: 'In the alley',
      },
      chart: {
        compare: 'compared to the previous month',
        title: 'Market in 3 years',
        average_price: 'Average price',
        number_posts: 'Number posts',
      },
      average_price: 'Average price',
      number_posts: 'Number posts',
      compare: 'compared to the previous quarter',
      helps: 'Analytics helps you',
      develop: 'This feature is in development.',
    },
    asset: {
      breadcrumb: {
        asset: 'Content',
        overview: 'Dashboard',
      },
      stat: {
        success: 'Success',
        scheduled: 'Scheduled',
        total: 'Total posts',
        month: 'post in month',
        sum: 'total post',
      },
      btn: {
        remove_filter: 'Reset',
        real_estate: 'Real estate',
        social: 'Social media',
        edit: 'Edit',
        schedule: 'Post',
        detail: 'Detail',
      },
      filter: {
        type: 'Type',
        start_day: 'Start day',
        end_day: 'End day',
        search: 'Search by title',
      },
      table: {
        head: {
          type: 'Type',
          avatar: 'Avatar',
          title: 'Title',
          city: 'Location',
          created_day: 'Created at',
          updated_day: 'Updated at',
        },
      },
      detail: {
        title: 'Detail post',
        table: {
          head: {
            platform: 'Platform',
            title: 'Title',
            date: 'Date',
            display: 'Display',
            status: 'Status',
            view: 'View',
            impression: 'Impression',
          },
        },
        tab: {
          all: 'All',
          active: 'Active',
          inactive: 'Inactive',
          hidden: 'Hidden',
          remove: 'Removed',
        },
        btn: {
          back: 'Back',
          link: 'Go to the post',
        },
        dialog: 'Filter post',
        notification: 'Notification',
        collapse: 'Collapse',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Platform',
            id_balance: 'ID/Balance',
            select_package: 'Select package',
            title_balance: 'Title/Balance',
            note: 'Note',
            package: 'Package',
            date_post: 'Date post',
            day_post: 'Day post',
            time_post: 'Time post',
            name_avatar: 'Name/Avatar',
          },
          body: {
            your_page: 'Your page',
            your_group: 'Your group',
            other_group: "Someone else's group",
          },
        },
        btn: {
          edit: 'Edit',
          back: 'Back',
          done: 'Done',
        },
        tab: {
          real_estate: 'Real estate',
          social: 'Social media',
        },
        current_asset: 'Current content',
        spam: "We add a 5 minute delay between messages so you don't get marked as spam",
        collapse: 'Collapse',
        type: 'Type',
      },
      breadcrumb: {
        home: 'Dashboard',
        calendar: 'Calendar',
      },
      btn: {
        schedule: 'Schedule',
      },
      title: {
        dialog: 'Filter post',
        notification: 'Notification',
      },
    },
    site: {
      action: {
        link_account: 'real estate platform',
      },
      breadcrumb: {
        home: 'Dashboard',
        account: 'Account',
      },
      btn: {
        add: 'Add',
        real_estate: 'Real estate',
        social: 'Social media',
        collapse: 'Collapse',
        view: 'View',
        edit: 'Edit',
        connect: 'Connect',
        close: 'Close',
        save: 'Check and save',
      },
      tab: {
        all: 'All',
        real_estate: 'Real estate',
        social: 'Social media',
      },
      form: {
        table: {
          head: {
            platform: 'Platform',
            id_balance: 'ID/Balance',
            name: 'Name account',
            status: 'Status',
            name_avatar: 'Name/Avatar',
          },
          body: {
            connected: 'Connected',
            disconnect: 'Disconnect',
          },
        },
        field: {
          password: 'Password',
          username: 'Username',
          is_branch: 'Sub-Account/Business',
        },
        error: {
          required: 'Please fill in here',
        },
      },
    },
    social: {
      platform: 'Add social media platform',
      message: {
        empty_page: 'You have not joined any pages yet',
        empty_group: 'You have not joined any groups yet',
        empty_installed: `Couldn't find information about groups that added the Uptin app`,
      },
      tab: {
        fanpage: 'Fanpage',
        own_group: 'Your group',
        installed_group: 'The group connected to Uptin',
      },
      btn: {
        prev: 'Previous',
        next: 'Next',
        back: 'Back',
        copy_link: 'Copy group link',
      },
      form: {
        search: 'Search by group name...',
      },
      snackbar: {
        connected: 'Established connection',
        copied: 'Copied path!',
      },
      popup: {
        content: `To be able to post to the group “<span style="font-weight: bold">#</span>", you need to contact the Group Administrator and ask them to <span style="font-weight: bold">add Uptin app</span> in the App section of the group. Find the group on facebook or copy this link and paste it in your browser.`,
      },
    },
    create_asset: {
      breadcrumb: {
        home: 'Dashboard',
        asset: 'Content',
        create_asset: 'Create content',
        edit_asset: 'Edit content',
      },
      real_estate: {
        title_create: 'Create real estate content',
        title_edit: 'Edit real estate content',
        tab: {
          general: 'General information',
          detail: 'Detail information',
        },
        btn: {
          next_step: 'Next step',
          complete: 'Complete',
          amenitie: 'Near amenities',
          convince: 'Convince',
          back: 'Back',
          close: 'Close',
          add_amenitie: 'Add to description',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
        },
        form: {
          field: {
            asset_type: 'Type of real estate',
            asset_detail: 'Real estate details',
            city: 'City',
            district: 'District',
            ward: 'Ward',
            street: 'Street',
            price: 'Price (VNĐ)',
            area: 'Area (m2)',
            actual_area: 'Usable area (m2)',
            demand: {
              title: 'Demand*',
              sell: 'Sell',
              rent: 'Rent',
              buy: 'Want to buy',
              employ: 'Want to rent',
            },
            who: {
              title: 'You are*',
              broker: 'Agency',
              owner: 'Owner',
              developer: 'Developer',
              investor: 'Investor',
            },
            bedroom: 'Bedroom',
            toilet: 'Toilet',
            legal_document: 'Legal documents',
            contact_info: 'Contact info',
            address: 'Address',
            hand_overs: {
              project: 'Project',
              under_construction: 'Under construction',
              slacking: 'Incomplete',
              handing_over: 'Handing over',
              complete: 'Complete',
              red_book: 'Red book',
              house_ownership: 'Pink book',
              sale_contract: 'Sale contract',
              valid_documents: 'Valid documents',
            },
            title: 'Title',
            short_title: 'Short (30-50 characters)',
            long_title: 'Long (45-90 characters)',
            description: 'Detailed description (up to 1500 characters)...',
            image: {
              title: 'Images',
              heading: 'Minimum 3 photos -Up to 6 photos',
              first_content: '-Drag and drop or click to select photos',
              second_content: '-Maximum image size 3Mb',
              third_content: '-The first photo from left to right will be the cover photo',
            },
          },
          error: {
            select: 'Please choose',
            required: 'Please fill in here',
            price: 'Please enter the correct price',
            area: 'Please enter the correct area',
            short_title: {
              min: 'Title should be at least 30 characters',
              max: 'Short title up to 50 characters',
            },
            long_title: {
              min: 'Title should be at least 45 characters',
              max: 'Long title up to 90 characters',
            },
            description: {
              min: 'Detailed description of at least 200 characters',
              max: 'Detailed description up to 1500 characters',
            },
            images: {
              min: 'Please upload at least 3 photos',
              max: 'Please upload up to 6 photos',
            },
          },
        },
      },
      social: {
        title_create: 'Compose social media posts',
        title_edit: 'Editing social media posts',
        btn: {
          rewrite: 'Rewrite',
          complete: 'Complete',
          google_format: 'Google Post Format',
          instagram_format: 'Instagram post format',
        },
        message: {
          create_success: 'Create content successfully',
          edit_success: 'Update content successfully',
          check_error: 'Please double check the information: ',
          hashtag_error: 'Add some keywords related to your problem',
        },
        form: {
          field: {
            keyword: 'Keywords "," separated by commas',
            description: 'Fill in something interesting...',
            image: {
              title: 'Images',
              heading: 'Upload up to 6 photos',
              first_content: '-Drag and drop or click to select photos',
              second_content: '-Maximum image size 3Mb',
              third_content: '-The first photo from left to right will be the cover photo',
            },
          },
          error: {
            required: 'Please fill in here',
            description: {
              min: 'Enter at least 50 characters',
              invalid: 'Content must be more than 50 characters (excluding emojis)',
            },
            images: {
              max: 'Please upload up to 6 photos',
            },
          },
        },
      },
    },
    notification: {
      you: 'You',
      breadcrumb: {
        general: 'Dashboard',
        notification: 'Notification',
        all_notification: 'All notifications',
      },
      menu: {
        setting: 'Notification setting',
        mark_read: 'Mark all as read',
      },
      btn: {
        collapse: 'Collapse',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Quest',
        general: 'Dashboard',
        user: 'User',
      },
      detail: 'Quest details:',
      multi: 'amount of money',
      vnd: 'VND',
      members: 'members',
      title: {
        reward_login_daily: 'Login daily',
        reward_daily_scheduled_bds: 'Post a real estate post',
        reward_daily_scheduled_mxh: 'Post a social media post',
        reward_linking_account: 'Successfully linked account',
        promo_linking_facebook_group: 'Add Uptin app to Facebook group',
        promo_daily_topup: 'First recharge of the day',
        promo_first_topup: 'First deposit',
      },
      description: {
        reward_login_daily:
          '- Reward: 5,000 VND\n- Requirements: Log in to Uptin through Google or Facebook\n- Task type: Daily',
        reward_daily_scheduled_bds:
          '- Reward: 2,000 VND\n- Requirements: Schedule and successfully post 01 real estate listing per day\n- Task type: Daily',
        reward_daily_scheduled_mxh:
          '- Reward: 2,000 VND\n- Requirements: Schedule and successfully post 01 social media listing per day\n- Task type: Daily',
        reward_linking_account:
          '- Reward: 5,000 VND\n- Requirements: Connect 1 real estate/social media account to Uptin for the first time and succeed.\n- Task type: One-time only',
        promo_linking_facebook_group:
          '- Reward: 10 VND x Number of group members\n- Requirements: Must be the group admin and the first person to install the Uptin application in the Facebook group. The group must have over 100 members.\n- Task type: One-time only',
        promo_daily_topup:
          '- Reward: Double the deposit amount (maximum reward of 50,000 VND)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: Daily',
        promo_first_topup:
          '- Reward: Double the deposit amount (unlimited)\n- Requirements: Payment via Momo, VNPay, or bank transfer\n- Task type: One-time only',
      },
      status: {
        completed: 'Completed',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Notification',
        overview: 'Dashboard',
        create_notification: 'Create notification',
      },
      tab: {
        list: 'List',
        approve: 'Pending Requests',
        audience: 'Audience',
        content: 'Content',
      },
      table: {
        head: {
          image: 'Image',
          title: 'Title',
          status: 'Status',
          budget: "Budget <br /> Recipient's",
          date_send: 'Delivery date',
          content: 'Content',
        },
      },
      form: {
        label: {
          demographic: 'Demographic',
          date_picker: 'Choose a date',
        },
        field: {
          area: {
            title: 'Area',
            northern: 'Northern',
            central: 'Central',
            southern: 'Southern',
          },
          city: 'City',
          career: {
            title: 'Carrer',
            broker: 'Broker',
            owner: 'Owner',
            developer: 'Developer',
            investor: 'Investor',
          },
          asset_type: 'Type of real estate',
          asset_detail: 'Real estate details',
          demand: {
            title: 'Demand',
            sell: 'Sell',
            rent: 'Rent',
            buy: 'Buy',
            employ: 'Employ',
          },
          all: 'All',
          image: {
            heading: 'Upload image',
            first_content: '-Drag and drop or click to select',
            second_content: '-Maximum image size 5Mb',
            third_content: '-The image is 256x256 px',
          },
          title: 'Title(6-80 characters)',
          content: 'Content(10-150 characters)',
          link: 'Notification link',
        },
        error: {
          required: 'Please fill in here',
          image: {
            required: 'Please upload a photo',
            only_one: 'Please upload 1 photo',
            format: 'Image must be 256x256 px',
          },
          title: {
            min: 'Enter at least 6 characters',
            max: 'Enter up to 80 characters',
          },
          content: {
            min: 'Enter at least 10 characters',
            max: 'Enter up to 150 characters',
          },
          link: {
            format: 'Please enter the correct link format',
          },
        },
      },
      preview: 'Preview',
      btn: {
        delete: 'Delete',
        approve: 'Approve',
        reject: 'Reject',
        collapse: 'Collapse',
        create_notification: 'Create notification',
        next: 'Next step',
        back: 'Back',
        complete: 'Submit',
      },
      status: {
        deliveried: 'Deliveried',
        canceled: 'Denied',
        pending: 'Pending',
        scheduled: 'Scheduled',
      },
      message: {
        create_success: 'Created notification success',
        deleted_success: 'Deleted success notification',
        deleted_fail: 'Deleted failure notification',
        approved_success: 'Approved success notification',
        approved_fail: 'Approve failed notification',
        denied_success: 'Rejected success notification',
        denied_fail: 'Reject failure notification',
      },
    },
  },
  translate_catalog: {
    city: ['City', 'Province'],
    district: ['City', 'Ward', 'District', 'Town', 'District'],
    ward: ['Town', 'Commune', 'Ward'],
    street: ['Street', 'Street', 'Provincial Highway', 'Street', 'Highway', 'Avenue'],
    demands: ['Sell', 'Rent', 'Buy', 'Employ'],
    categories: {
      apartment: {
        title: 'Apartment',
        apartment: 'Apartment',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: ' Serviced apartment, mini',
        housing_project: 'Group, dormitory',
        officetel: 'Officetel',
      },
      house: {
        title: 'House',
        town_house: 'Street house, facade',
        alley_house: 'Lane house, alley',
        villa: 'Villa house',
        terrace_house: 'Townhouse',
      },
      land: {
        title: 'Land',
        industrial_land: 'Industrial land',
        agricultural_land: 'Agricultural land',
        residential_land: 'Residential land',
        ground_project: 'Project land',
      },
      commercial: {
        title: 'Office, Business premises',
        business_premises: 'Business premises',
        office: 'Office',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Inn room',
        rooming_house: 'Inn room',
      },
    },
  },
  today: 'Today',
  empty_data: 'Empty',
  version: 'Version',
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
