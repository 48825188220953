import axios from 'axios';
// routes
import { PATH_AUTH } from '../routes/paths';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

let refreshTokenRequest = null;

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const rs = await axiosInstance.post("api/token/refresh/", {
    refresh: refreshToken
  });
  return rs.data;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    if(originalConfig.url === 'api/token/refresh/' && error.response.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      alert('Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại.');
      window.location.href = PATH_AUTH.login;
    } else if (error.response && error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        refreshTokenRequest = refreshTokenRequest || refreshToken();
        const newToken = await refreshTokenRequest;
        refreshTokenRequest = null;
        const { access, refresh } = newToken;
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        return axiosInstance(originalConfig);
      } catch (_error) {
        // eslint-disable-next-line no-alert
        return Promise.reject(_error);
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
