import { Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const UpdatePwaModal = () => {

  const [isOpenSwModal, setIsOpenSwModal] = useState(false)
  const { serviceWorkerUpdated, serviceWorkerRegistration } = useSelector(state => state.sw)

  useEffect(() => {
    if (serviceWorkerUpdated) {
      setIsOpenSwModal(true)
    }
  }, [serviceWorkerUpdated])

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.payload.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (
    <Dialog
      open={isOpenSwModal}
    >
      <DialogContent>
        <DialogContentText
          sx={{
            "display": "flex",
            "flexDirection": "column",
            "gap": "0.7rem"
          }}
        >
          <p>Cập nhật app phiên bản mới nhất</p>
          <Button onClick={() => updateServiceWorker()}>Cập nhật</Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
