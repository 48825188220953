import { useEffect, useRef, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PWAPrompt from 'react-ios-pwa-prompt';
import ReactGA from 'react-ga4';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// configs
import { ENVIRONMENT } from './config';
import { UpdatePwaModal } from './components/UpdatePwaModal';
// hooks
import useLocales from './hooks/useLocales';

// utils
import axios from './utils/axios';

// ----------------------------------------------------------------------

const GG_TRACKING_ID = 'G-BWF8243404';

export default function App() {
  const { translate } = useLocales('pwa');

  const [authGoogleKey, setAuthGoogleKey] = useState(
    '423923046811-687s6qnbg3talmdesuriooqi2jvcefdm.apps.googleusercontent.com'
  );

  ReactGA.initialize(GG_TRACKING_ID);

  // Zoho Sales Iq Script:
  useEffect(() => {
    if (ENVIRONMENT === 'production') {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');

      const widgetCode = 'd753d57d2f9988fa4c28bdcabdee7312ecac1ecfa6066d2976c56ad4b1fff64b';
      const url = 'https://salesiq.zoho.com/widget';

      const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    }
  });

  useEffect(() => {
    (async () => {
      const {
        data: { sso_client_id: ssoClientId },
      } = await axios.get('api/v5/commons/configs/');
      const { google } = ssoClientId;
      setAuthGoogleKey(google);
    })();
  }, []);

  return (
    <GoogleOAuthProvider clientId={authGoogleKey}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
        <UpdatePwaModal />
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={30}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
          copyShareButtonLabel={translate('step_one')}
          copyAddHomeButtonLabel={translate('step_two')}
          copyTitle={translate('title')}
          copyBody={translate('content')}
        />
      </MotionLazyContainer>
    </GoogleOAuthProvider>
  );
}
