import { useState, useCallback, useRef, useEffect } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
// GoogleReCaptcha
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useResponsive from '../../../hooks/useResponsive';
// components
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, googleLogin, facebookLogin, verify } = useAuth();
  const { themeMode } = useSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const [googleResponseCaptcha, setGoogleResponseCaptcha] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isUseEmail, setUseEmail] = useState(false);

  const smUp = useResponsive('up', 'sm');

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [isSubmittingGoogle, setSubmittingGoogle] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    (async () => {
      try {
        // Query params
        // Facebook
        const token = searchParams.get('token');
        const refresh = searchParams.get('refresh');
        const loginType = searchParams.get('login_type');
        const errorMessage = searchParams.get('message');
        // Google
        const code = searchParams.get('code');
        // Hidden query params on URL
        setSearchParams({});

        if (errorMessage) {
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }

        if (token && refresh && loginType && ['facebook', 'google-auth2'].indexOf(loginType) > -1) {
          // Verify token
          await verify(token, refresh);
        } else if (code) {
          console.log('submit google 1st');
          setSubmittingGoogle(true);
          await googleLogin(code);
          setSubmittingGoogle(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [searchParams]);

  const onSubmit = async (data) => {
    try {
      // get recaptcha token
      const token = await executeRecaptcha('login');
      await login(data.email, data.password, token);
      setRefreshReCaptcha((r) => !r);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  const onChangeUseEmail = (use) => {
    setUseEmail(use);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Đăng nhập Uptin
          </Typography>
        </Box>
      </Stack>
      <>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">Email hoặc mật khẩu không đúng</Alert>}
            {!!errors.afterSubmit && (
              <Alert severity="error">Bạn có thể đăng nhập bằng Google thay cho Email và Mật Khẩu</Alert>
            )}
            <RHFTextField name="email" label="Email" />

            <RHFTextField
              name="password"
              label="Mật khẩu"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label="Nhớ tài khoản" />
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgetPassword}>
              Quên mật khẩu?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Đăng nhập
          </LoadingButton>
        </FormProvider>
      </>
    </>
  );
}
