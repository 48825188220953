import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Badge } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useResponsive from '../../../hooks/useResponsive';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// utils
import { numberWithCommas } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'setting',
    linkTo: `${PATH_DASHBOARD.user.account}?activeTab=general`,
  },
  // {
  //   label: 'payment',
  //   linkTo: `${PATH_DASHBOARD.user.account}?activeTab=payment`,
  // },
  {
    label: 'change_password',
    linkTo: `${PATH_DASHBOARD.user.account}?activeTab=change_password`,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const { translate } = useLocales('nav');

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const isSmaller450 = useResponsive('between', null, 0, 450);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleQuestRedirect = () => {
    navigate(PATH_DASHBOARD.quest.list);
  };

  const handlePaymentRedirect = () => {
    navigate(`${PATH_DASHBOARD.user.account}?activeTab=payment`);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.phone}
          </Typography>
          {isSmaller450 && (
            <Stack direction={'column'} alignItems={'center'} rowGap={'12px'} sx={{ mt: '12px' }}>
              <Badge badgeContent={7} color="error">
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  columnGap={'8px'}
                  sx={{
                    fontSize: '13px',
                    color: '#3366FF',
                    border: '1px solid #3366FF',
                    borderRadius: '8px',
                    px: '10px',
                    py: '4px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={handleQuestRedirect}
                >
                  <Iconify icon={'ph:game-controller-bold'} width={20} height={20} />
                  {numberWithCommas(user?.promo + user?.reward, '.')}
                </Stack>
              </Badge>

              <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={'8px'}
                sx={{
                  fontSize: '13px',
                  color: '#fff',
                  background: '#3366FF',
                  px: '10px',
                  py: '4px',
                  borderRadius: '8px',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
                onClick={handlePaymentRedirect}
              >
                <Iconify icon={'bx:dollar-circle'} width={20} height={20} />
                {numberWithCommas(user?.balance, '.')}
              </Stack>
            </Stack>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {translate(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
