import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const slice = createSlice({
  name: 'service-worker',
  initialState,
  reducers: {
    swUpdate(state, action){
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { swUpdate } = slice.actions;