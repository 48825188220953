// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  plusMath: getIcon('ic_plus_math'),
  setting: getIcon('ic_setting'),
  share: getIcon('ic_share'),
  list: getIcon('ic_list'),
  notification: getIcon('ic_notification'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'general', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      {
        title: 'detail',
        path: PATH_DASHBOARD.general.detail,
        icon: ICONS.analytics,
      },
    ],
  },

  // QUICK ACTION
  // ----------------------------------------------------------------------
  {
    subheader: ' ',
    items: [
      // SETTINGS
      {
        title: 'setting',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.setting,
      },
    ],
  },
];

export default navConfig;
