import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, uniqBy } from 'lodash';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_SOCIAL_URL = 'api/v1/quests/';
const specialQuestsDaily = ['promo_first_topup'];

const initialState = {
  isLoading: false,
  error: null,
  quests: [],
  totalQuest : 0,
  questCompleted: 0
};

const slice = createSlice({
  name: 'quest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SOCIAL INFO BY SOCICAL NAME
    getQuestsSuccess(state, action) {
      const { data } = action.payload;
      let temp = uniqBy([...state.quests, ...data], 'id');
      temp = temp.sort((a, b) => (a.is_completed ? 1 : -1));
      state.quests = [...temp];
      state.totalQuest = temp.filter((item) => item.is_daily || specialQuestsDaily.indexOf(item.name) !== -1).length;
      state.questCompleted = temp.filter((item) => item.is_completed).length;
    },
    markQuestCompleted(state, action) {
      const { questId } = action.payload;
      const index = state.quests.findIndex((item) => item.id === questId);
      if (index !== -1) {
        state.quests[index] = { ...state.quests[index],is_completed: true };
        const temp = state.quests.sort((a, b) => (a.is_completed ? 1 : -1));
        state.quests = [...temp];
        state.totalQuest = temp.filter((item) => item.is_daily || specialQuestsDaily.indexOf(item.name) !== -1).length;
        state.questCompleted = temp.filter((item) => item.is_completed).length;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export async function getListQuest(params) {
  const response = await axios.get(`${BASE_SOCIAL_URL}`, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getQuestsSuccess({data: results }));

    if (next && undefined === params.page) {
      // first call
      const count = response.data.count;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getListQuest(params);
        })();
      }
    }
  }
}

export async function markCompleted(questId) {
    dispatch(slice.actions.markQuestCompleted({questId}));
}