import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Autocomplete } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutoComplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  initValue: PropTypes.string
};

export default function RHFAutoComplete({ name, label,initValue = '', required = false, ...other }) {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            {...other}
            onChange={(event, newValue) => field.onChange(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label={label} error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />
    );
}
