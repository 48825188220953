import PropTypes from 'prop-types';
import { useState } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
import { BlockPicker } from 'react-color';
// @mui
import { Box, FormControlLabel, Popover, Stack, Typography } from '@mui/material';
// popup over
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

// ----------------------------------------------------------------------

RHFColorPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  currentColor: PropTypes.string,
};

export default function RHFColorPicker({ name, label, currentColor, ...other }) {
  const { control } = useFormContext();

  return (
    <PopupState variant="popover" popupId={name}>
      {(popupState) => {
        return (
          <Typography
            component="div"
            style={{ zIndex: 999, position: 'relative' }}
            sx={{
              backgroundColor: '#fff',
              cursor: 'pointer',
              borderRadius: '3px',
              '&:hover': {
                filter: 'brightness(0.92)',
                backgroundColor: '#EBEBEB',
              },
            }}
          >
            <Stack
              {...bindTrigger(popupState)}
              direction="row"
              alignItems="center"
              justifyContent={'start'}
              columnGap={1}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: '13px',
                color: '#000',
                p: '5px',
                borderRadius: '5px',
              }}
            >
              <Typography
                component="div"
                width="100%"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontSize: '13px',
                  color: '#000',
                }}
              >
                {label}
              </Typography>
              <Box sx={{ width: '30px', height: '30px', bgcolor: currentColor, flexShrink: 0 }} />
            </Stack>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: '#fff',
                },
              }}
            >
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BlockPicker
                    color={value}
                    {...other}
                    onChangeComplete={(color, event) => onChange(color.hex)}
                    {...other}
                  />
                )}
              />
            </Popover>
          </Typography>
        );
      }}
    </PopupState>
  );
}
